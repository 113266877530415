<template>
  <div class="distribution_edit">
    <div class="content">
      <span class="title">{{routeData.id?'编辑':'添加'}}经销商/代理商</span>
      <el-form :model="form" :rules="rules" class="info" ref="form">
        <el-form-item prop="name" class="item">
          <label>企业名称：</label>
          <el-input v-model="form.name" clearable/>
        </el-form-item>
        <el-form-item prop="phone" class="item">
          <label>电话：</label>
          <el-input v-model="form.phone" clearable/>
        </el-form-item>
        <el-form-item prop="email" class="item">
          <label>Email：</label>
          <el-input v-model="form.email" clearable/>
        </el-form-item>
        <el-form-item prop="website" class="item">
          <label>网址：</label>
          <el-input v-model="form.website" clearable/>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      <el-button size="small" @click="goRoute">取消</el-button>
      <el-button size="small" type="primary" @click="checkFormSubmit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { addDistributor,editDistributor } from '@/api/soc.js'

export default {
  name: "manufacturer",
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value.startsWith('http')) {
        callback(new Error('请输入正确的网址'));
      } else {
        callback();
      }
    };
    return {
      routeData:{},
      isEdit:false,
      form:{
        name:'',
        phone:'',
        email:'',
        website:''
      },
      rules:{
        name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        phone:[
          { required: true, message: '请输入电话', trigger: 'blur' },
          { pattern:new RegExp("^(?:(?:\\+|00)86)?1[3-9]\\d{9}$|^0\\d{2,3}-?\\d{7,8}$"), message: "请输入合法手机号/电话号", trigger: ['blur', 'change'] }
        ],
         email:[
          { required: true, message: '请输入Email', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
         website:[
          { required: true, message: '请输入网址', trigger: 'blur' },
          { validator: validatePass, trigger: ['blur', 'change'] }
        ],
      }
    };
  },
  activated() {
    this.routeData = this.$route.query
    if(this.routeData?.id){
      this.form =  this.routeData
    }
  },
  deactivated(){
    this.isEdit = false
    Object.assign(this.form,this.$options.data().form)
    this.$nextTick(()=>{
      this.$refs.form.clearValidate()
    })
  },
  methods:{
    goRoute(){
      this.$router.go(-1)
    },
    // 提交校验
    checkFormSubmit(){
       this.$refs.form.validate((valid) => {
          if (valid) {
            this.onSave()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    //修改经销商信息
    onSave(){
      let Api = this.routeData.id ? editDistributor : addDistributor
      Api(this.form).then(res=>{
        if(res.code==200){
          this.$message.success('操作成功')
          this.$router.go(-1)
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.distribution_edit{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 180px);
  text-align: left;
  color: #E8E8E8;
  .content{
    background: #262626;
    border-radius: 8px;
    position: relative;
    padding: 23px 39px;
    label{
      font-size: 16px;
    }
    .title{
      font-size: 20px;
    }
    .info{
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
  .footer{
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    line-height: 60px;
    background: #262626;
    margin: 0px 2px;
    padding-right: 32px;
  }
}
</style>
