// 运维管理
import {get, post ,put ,del} from "../utils/http";

//获取厂家详情
export function getfacturerDetail() {
  return get(`/manufacturer/detail`)
}
//获取厂家简要
export function getfacturerSimple() {
  return get(`/manufacturer/simple`)
}
//编辑厂家
export function editFacturer(params) {
  return put(`/manufacturer`,params)
}
//获取经销商列表
export function getDistributor(params) {
  return get(`/distributor/page`,params)
}
//获取经销商详情
export function getDistributorDetail(params) {
  return get(`/distributor/detail/to-manufacturer`,params)
}
//添加经销商
export function addDistributor(params) {
  return post(`/distributor`,params)
}
//编辑经销商
export function editDistributor(params) {
  return put(`/distributor`,params)
}
//删除经销商
export function deleteDistributor(id) {
  return del(`/distributor/${id}`)
}